/**
 * A utility class to manage URL parameters and reloading with specified parameters.
 */
class PageParams {

    /**
     * Reloads the current page, preserving the scroll position.
     *
     * @returns {void}
     */
    static reloadPreservingScrollPosition() {
        // Save the current scroll position
        const scrollY = window.scrollY;
        sessionStorage.setItem('scrollPosition', scrollY);

        // Reload the page
        location.reload();
    }

    /**
     * Reloads the current page with a specified GET parameter added to the URL.
     * If the parameter is already present in the URL, it simply reloads the page.
     *
     * @param {string} paramName - The name of the GET parameter to append to the URL.
     * @param {string} paramValue - The value of the GET parameter to append to the URL.
     * 
     * @returns {void}
     */
    static reloadWithGetParam(paramName, paramValue) {
        // Get the current page URL
        const currentUrl = new URL(window.location.href);

        // Check if the parameter is already in the URL
        if (!currentUrl.searchParams.has(paramName)) {
            // Save current scroll position
            const scrollY = window.scrollY;
            sessionStorage.setItem('scrollPosition', scrollY);

            // Append the parameter to the URL
            currentUrl.searchParams.append(paramName, paramValue);

            // Reload the page with the updated URL
            history.pushState(null, document.title, currentUrl.toString());
        } else {
            // If the parameter already exists, just reload the page
            history.replaceState(null, document.title, window.location.href);
        }

        location.reload();
    }

    /**
     * Removes the specified GET parameter from the URL without reloading the page.
     *
     * @param {string} paramName - The name of the GET parameter to remove from the URL.
     * 
     * @returns {void}
     */
    static removeGetParam(paramName) {
        // Get the current page URL
        const currentUrl = new URL(window.location.href);

        // Check if the parameter exists in the URL
        if (currentUrl.searchParams.has(paramName)) {
            // Remove the parameter
            currentUrl.searchParams.delete(paramName);

            // Update the URL without reloading the page
            window.history.replaceState({}, '', currentUrl.toString());
        }
    }
}

window.PageParams = PageParams;
export default PageParams;
